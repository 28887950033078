<template>
  <div class="phone-page">
    <div class="join-container auth-bg">
      <div>
        <img
          src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
          class="studio-logo"
          width="91"
          alt="BIGC-STUDIO black Logo"
        />
      </div>
      <div class="studio-join-form">
        <div class="studio-join-header">
          <img
            src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
            class="studio-logo"
            width="91"
            alt="BIGC-STUDIO black Logo"
          />
        </div>
        <div class="studio-join">
          <h2>스튜디오 이용을 위해 <br />휴대폰 번호를 인증해 주세요!</h2>

          <div class="name">
            <input-underline
              label="활동명"
              placeholder="이름 또는 활동명을 입력해주세요"
              name="displayName"
              :default-value="state.displayName"
              :error-message="state.errorMessage.displayName"
              @updateData="
                (value) => {
                  actions.updateDisplayName(value);
                }
              "
            ></input-underline>
          </div>
          <div>
            <div class="phone">
              <div
                :class="{
                  'border-primary': state.phone && !state.errorMessage.phone,
                  'border-gray-third':
                    !state.phone && !state.errorMessage.phone,
                  'border-red-50': state.errorMessage.phone,
                }"
              >
                <div class="input-title">
                  <label class="b-text-3">휴대폰 번호</label>
                </div>
                <div class="input-phone-number">
                  <select
                    class="country-code"
                    :value="state.code"
                    @change="actions.updateCountryCode($event)"
                  >
                    <option
                      v-for="(item, index) in countryCodes"
                      :key="`country-${index}`"
                      :value="item.dial_code"
                    >
                      {{ item.dial_code }}
                    </option>
                  </select>
                  <!--                <div class="default-country-code">-->
                  <!--                  {{ state.code }}-->
                  <!--                </div>-->

                  <input
                    class="phone-number b-text-1"
                    type="number"
                    :disabled="state.isPhoneValidationSuccess"
                    :value="state.phone"
                    placeholder="숫자만 입력해주세요"
                    @input="actions.updatePhone($event)"
                  />
                </div>
              </div>
              <button
                class="sub-text-s2 bg-gray-010"
                :class="{
                  'text-gray-third':
                    !state.phone || state.isPhoneValidationSuccess,
                }"
                :disabled="!state.phone || state.isPhoneValidationSuccess"
                @click="actions.getPhoneValidationCode()"
              >
                {{ state.phoneValidateBtnTxt }}
              </button>
            </div>
            <div class="b-text-3 text-red-50 text-start error-message">
              {{ state.errorMessage.phone }}
            </div>
          </div>

          <div>
            <div class="phone-confirm">
              <div
                :class="{
                  'border-primary':
                    state.phoneValidationCode &&
                    !state.errorMessage.phoneValidationCode,
                  'border-gray-third':
                    !state.phoneValidationCode &&
                    !state.errorMessage.phoneValidationCode,
                  'border-red-50': state.errorMessage.phoneValidationCode,
                }"
              >
                <div class="input-title">
                  <label class="b-text-3 text-truncate">인증번호 확인</label>
                </div>
                <input
                  class="b-text-1"
                  type="number"
                  :value="state.phoneValidationCode"
                  :disabled="!state.phone || state.isPhoneValidationSuccess"
                  placeholder="인증번호를 입력해주세요"
                  @input="actions.updatePhoneValidationCode($event)"
                />
                <span
                  v-if="state.sendValidateCode"
                  class="text-red-50 authentication-time"
                >
                  {{ state.displayTimer }}
                </span>
              </div>
              <button
                class="sub-text-s2 bg-gray-010"
                :class="{
                  'text-gray-third':
                    !state.sendValidateCode || state.isPhoneValidationSuccess,
                }"
                :disabled="
                  !state.sendValidateCode || state.isPhoneValidationSuccess
                "
                @click="actions.verifyPhoneVerificationCode"
              >
                인증하기
              </button>
            </div>
            <div class="b-text-3 text-red-50 text-start error-message">
              {{ state.errorMessage.phoneValidationCode }}
            </div>
          </div>

          <div class="info">
            휴대폰 인증 시 혜택 정보 알람 수신 동의 처리 됩니다. <br />
            크리에이터 혜택 및 콘텐츠 협업 제안 등을 위해 연락드릴 수 있으며,
            원치않으신 분들은 계정 정보에서 수신 거부가 가능합니다.
          </div>

          <div class="studio-join-btns">
            <button-basic
              text="스튜디오 시작하기"
              :disabled="!state.ableToGoStudio"
              @action="actions.goToConsole()"
            ></button-basic>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { diffDay } from "../../../helper/date";
import moment from "moment-timezone";
import * as countryCode from "../../../consts/country-code.json";
import swal from "../../../helper/swal";
import helper from "@/helper";
import ApiService from "@/api";
import InputUnderline from "../../../components/console/inputs/InputUnderline";
import ButtonBasic from "../../../components/console/buttons/ButtonBasic";
import { clear } from "core-js/internals/task";

export default {
  name: "Phone",
  components: { InputUnderline, ButtonBasic },
  setup() {
    const router = useRouter();
    const store = useStore();

    const timer = ref();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      displayName: "",
      phone: "",
      code: "+82",
      phoneResourceId: "",
      phoneValidationCode: "",
      isPhone: computed(() => {
        return state.phone.length > 0;
      }),
      isPhoneValidationCode: computed(() => {
        return state.phoneValidationCode;
      }),
      sendValidateCode: false,
      isPhoneValidationSuccess: false,
      phoneValidateBtnTxt: computed(() => {
        return state.sendValidateCode ? "재발송" : "인증번호 발송";
      }),
      errorMessage: {
        displayName: "",
        phone: "",
        phoneValidationCode: "",
      },
      ableToGoStudio: computed(() => {
        return (
          state.displayName.length > 0 &&
          state.code.length > 0 &&
          state.phone.length > 0 &&
          state.isPhoneValidationSuccess &&
          !state.waitingResponse
        );
      }),
      displayTimer: "",
      waitingResponse: false,
    });

    watch(
      () => state.user.phone,
      (phone) => {
        if (phone) {
          state.activeStartBtn = true;
        }
      }
    );

    onBeforeMount(() => {
      state.displayName = state.user.userProfile.displayName
        ? state.user.userProfile.displayName
        : "";
      state.phone = state.user.phone ? state.user.phone : "";
    });

    const countdownTimer = (expiredAt) => {
      let remainTime = diffDay(moment(expiredAt));

      if (remainTime.sec < 0) {
        clearInterval(timer.value);
        return;
      }
      state.displayTimer = `${remainTime.min}:${remainTime.sec}`;
    };

    const startTimer = (expiredAt) => {
      timer.value = setInterval(() => countdownTimer(expiredAt), 1000);
    };

    const countryCodes = countryCode.default;

    // 크리에이터 명 수정
    const putUserDisplayName = () => {
      let payload = { displayName: state.displayName };
      store.dispatch("auth/putUserProfile", payload).then((res) => {
        if (!res.data.success) {
          state.waitingResponse = false;
        }
      });
    };

    const actions = {
      updateDisplayName: (value) => {
        state.displayName = value.trim();
      },
      updateCountryCode: (e) => {
        state.code = e.target.value;
      },
      updatePhone: (e) => {
        state.phone = e.target.value;
        if (state.errorMessage.phone) {
          state.errorMessage.phone = "";
        }
      },
      updatePhoneValidationCode: (e) => {
        state.phoneValidationCode = e.target.value;
        if (state.errorMessage.phoneValidationCode) {
          state.errorMessage.phoneValidationCode = "";
        }
      },
      getPhoneValidationCode: async () => {
        if (state.code.length === 0) {
          return;
        }
        if (state.phone.length === 0) {
          return;
        }

        if (!helper.validatePhoneNumber(state.phone)) {
          state.errorMessage.phone = "전화번호 형식을 확인해주세요.";
          return;
        }

        if (state.phoneValidationCode) {
          state.phoneValidationCode = "";
        }
        if (state.errorMessage.phoneValidationCode) {
          state.errorMessage.phoneValidationCode = "";
        }
        if (state.displayTimer) {
          state.displayTimer = "";
        }

        // FIXME ROMANO 더 나은 코드로 수정 필요
        state.phone = state.phone
          .replaceAll("-", "")
          .replaceAll(".", "")
          .replaceAll("e", "");

        clearInterval(timer.value);

        let formData = new FormData();
        let phoneNumber = `${state.code} ${state.phone}`;
        formData.append("phone", phoneNumber);

        let payload = formData;

        await ApiService.postAuthPhoneNumber(payload).then((res) => {
          if (res.data.success) {
            state.sendValidateCode = true;
            state.phoneResourceId = res.data.data.phoneResourceId;
            swal.successToast("휴대폰으로 인증번호가 발송되었습니다.");

            startTimer(res.data.data.codeExpiredAt);
          }
        });
      },
      verifyPhoneVerificationCode: async () => {
        let formData = new FormData();
        formData.append("code", state.phoneValidationCode);
        let payload = formData;
        store
          .dispatch("auth/postAuthPhoneVerificationCode", {
            phoneResourceId: state.phoneResourceId,
            data: payload,
          })
          .then((res) => {
            if (res.data.success) {
              swal.successToast("인증되었습니다.");
              clearInterval(timer.value);
              state.displayTimer = "";
              state.isPhoneValidationSuccess = true;
            } else {
              state.errorMessage.phoneValidationCode = res.data.message;
            }
          })
          .catch((err) => {
            state.errorMessage.phoneValidationCode = err.data.message;
          });
      },
      goToConsole: () => {
        state.waitingResponse = true;
        let fetchUser = setInterval(() => {
          store.dispatch("auth/fetchUser").then(async () => {
            if (state.user.phone) {
              clearInterval(fetchUser);
              await putUserDisplayName();
              state.waitingResponse = false;
              await router.push({ name: "console.home" });
            }
          });
        }, 1000);
      },
    };

    return { state, actions, countryCodes };
  },
};
</script>

<style src="./style.css" scoped></style>
